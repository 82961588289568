<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }"
                    >首页
                    </el-breadcrumb-item
                    >
                    <el-breadcrumb-item>交易查询</el-breadcrumb-item>
                    <el-breadcrumb-item>账户明细</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="admin_main_block admin_m15">
            <div class="header-form">
                <div class="admin_main_block_left">
                    <el-form>
                        <el-form-item label="账户编号">
                            <el-input
                                    v-model="where.accountNo"
                                    placeholder="请输入账户编号"
                                    clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="账户类型">
                            <el-select v-model="where.accountType" placeholder="请选择账户类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in accountType" :key="index" :label="item" :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="账户角色">
                            <el-select v-model="where.ownerRole" placeholder="请选择账户角色">
                                <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in ownerRole" :key="index" :label="item" :value="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
                            >条件筛选
                            </el-button
                            >
                        </el-form-item>
                    </el-form>
                </div>

                <div class="admin_main_block_right">
                    <el-row :gutter="20">
                        <el-col :span="12">

                        </el-col>
                        <el-col :span="12">
                            <el-button
                                    type="primary"
                                    @click="exportData"
                                    disabled
                                    icon="el-icon-tickets"
                            >导出数据
                            </el-button
                            >
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div class="admin_table_main">
                <el-table
                        :data="list"
                        v-loading="loading"
                        element-loading-text="拼命加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.1)"
                >
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="accountTypeText" label="账户类型"></el-table-column>
                    <el-table-column prop="bizType" label="类型"></el-table-column>
                    <el-table-column prop="ownerRoleText" label="角色"></el-table-column>
                    <el-table-column prop="amount" label="金额"></el-table-column>
                    <el-table-column prop="createTime" label="日期"></el-table-column>
                    <el-table-column prop="status" label="status"></el-table-column>
                    <el-table-column prop="flowId" label="业务流水号"></el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
        },
        props: {
            state: Number,
        },
        data() {
            return {
                list: [],
                date: "",
                row: {},
                loading: false,
                dialogDetailVisible: false,
                dialogEditVisible: false,
                totalData: 0, // 总条数
                pageSize: 20,
                currentPage: 0,
                rolesData: [],
                selectIds: [],
                where: {
                    "accountType": "",
                    "current": 4,
                    "ownerRole": "",
                    "size": 5,
                    "status": "",
                    "accountNo": this.$route.query.accountNo,
                },
                accountType:{
                    "PROFIT_PAY":"分润代发账户",
                    "PROFIT_BEAN":"惠豆",
                },
                ownerRole:{
                    'SHOP':'店铺',
                    'BD':'BD',
                    'PERSON':'个人',
                    'SERVICE_PROVIDER':'服务商',
                    'OPERATION_CENTER':'运营中心',
                    'SYSTEM':'系统/平台',
                },

            };
        },
        methods: {
            listData() {
                this.loading = true;
                this.where.current = this.currentPage;
                this.where.size = this.pageSize;
                this.$post(this.$api.accountBillList, this.where).then((res) => {
                    this.list = res.data.records;
                    this.loading = false;
                });
            },
            exportData() {
                // console.log("exportData");
            },

        },
        created() {
            this.listData();
        },
    };
</script>
<style lang="scss" scoped>
    .endDate {
        color: #c0c4cc;
    }

    .el-image {
        height: 100px;
        width: 100px;
    }

    .el-select {
        width: 120px;
    }
</style>
<style>
    .el-drawer__header span:focus {
        outline: 0 !important;
    }
</style>
